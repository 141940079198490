<template>
  <div class="modal-backdrop" v-if="showModal">
    <div class="modal-content__heading">
      <button class="close-button" @click="closeModal">
        <span class="material-symbols-rounded">close</span>
      </button>
    </div>
    <div class="modal-content">
      <form v-if="formType === 'desktop'" class="login__form form__desktop" method="post" :action="`//wager.${BackEndUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
        <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />

        <label for="account">Username:</label>
        <input class="login__form--inputs" type="text" name="account" id="account">

        <label for="password">Password:</label>
        <input class="login__form--inputs" type="password" name="Password" id="password">

        <div class="header-buttons">
          <button class="btn-login secondary-button secondary-button__betslip" type="submit">Login</button>
        </div>
      </form>

      <form v-if="formType === 'mobile'" class="login__form form__mobile" method="post" :action="`//mobile.${BackEndUrl}/DefaultLogin.aspx`">
        <input type="hidden" name="siteID" id="siteID" :value="`${DGS_SITEID}`" />
        <input type="hidden" name="errorURL" :value="`//www.${BackEndUrl}/?login-error`" />

        <label for="account">Username:</label>
        <input class="login__form--inputs" type="text" name="account" id="account">

        <label for="password">Contraseña:</label>
        <input class="login__form--inputs" type="password" name="Password" id="password">

        <div class="header-buttons">
          <button class="btn-login secondary-button secondary-button__betslip" type="submit">Login</button>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ModalComponentClassic',
  props: {
    formType: {
      type: String,
      default: 'desktop' // Default form type
    },
  },
  setup() {
    const BackEndUrl = "wagerwire.net";
    const DGS_SITEID = 449;
    return {
      BackEndUrl,
      DGS_SITEID
    };
  },
  data() {
    return {
      showModal: false
    };
  },
  methods: {
    openModal() {
      this.showModal = true;
    },
    closeModal() {
      this.showModal = false;
    }
  }
};
</script>
