<template>
  <div id="app">
    <AddFavorite v-if="isHomePage"/> <!-- Mostrar solo si estamos en la página de inicio -->
    <header-component/>
    <router-view/>
    <footer-component/>
  </div>
</template>

<script>
import AddFavorite from "@/components/add-favorite.vue";
import headerComponent from "@/components/header.vue";
import FooterComponent from "@/components/footer.vue";

export default {
  name: 'App',
  components: {
    AddFavorite,
    headerComponent,
    FooterComponent
  },
  computed: {
    isHomePage() {
      return this.$route.name === 'Home'; // Cambia 'Home' por el nombre de tu ruta de inicio
    }
  }
}
</script>