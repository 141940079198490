<template>
  <footer>
    <div class="footer">
      <div class="sub-footer container">
        <div class="row mt-3 footer-link justify-content-center">
          <div class="col-12">
            <p>Copyrights © 2024 All Rights Reserved by <a href="#">www.wagerwire.net</a></p>
          </div>
          <div class="col-12">
            <router-link to="/Casino/ResponsibleGambling" class="link">Responsible Gambling</router-link>
            <span>|</span>
            <router-link to="/Casino/PrivacyPolicy" class="link"> Privacy Policy</router-link>
            <span>|</span>
            <router-link to="/Casino/TermsAndConditions" class="link">Terms & Conditions </router-link>
            <span>|</span>
            <a href="https://agents.wagerwire.net/AgentSiteV2/" target="_blank" class="link">Reports</a>
          </div>
        </div>
      </div>
    </div>
    <!-- Botón de scroll con fade-in y una flecha SVG simple -->
    <div
      id="gotoTop"
      class="icon-angle-up"
      @click="scrollToTop"
      :style="{ opacity: showGoToTop ? 1 : 0 }"
    >
      <!-- SVG solo con la cabeza de la flecha -->
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
        <polyline points="6 15 12 9 18 15"></polyline>
      </svg>
    </div>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data() {
    return {
      showGoToTop: false, // Controla la visibilidad del botón
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      // Mostrar el botón si el usuario se ha desplazado más de 200 píxeles
      this.showGoToTop = window.scrollY > 50;
    },
    scrollToTop() {
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
  },
};
</script>