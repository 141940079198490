import { createRouter, createWebHistory } from 'vue-router';

const routes = [
  { path: '/', name: 'Home', component: () => import('../pages/Home.vue') },
  {
    path: '/Casino', component: () => import('../pages/Casino.vue'),
    children: [
      { path: 'CaribbeanStudPoker', component: () => import('../pages/internal-pages-casino/CaribbeanStudPoker.vue') },
      { path: 'Roulette', component: () => import('../pages/internal-pages-casino/RouletteEuropean.vue') },
      { path: 'Craps', component: () => import('../pages/internal-pages-casino/GameCraps.vue') },
      { path: 'SlotMachines', component: () => import('../pages/internal-pages-casino/SlotMachines.vue') },
      { path: 'Blackjack', component: () => import('../pages/internal-pages-casino/GameBlackjack.vue') },
      { path: 'Baccarrat', component: () => import('../pages/internal-pages-casino/GameBaccarrat.vue') },
      { path: 'ResponsibleGambling', component: () => import('../pages/internal-pages-casino/ResponsibleGambling.vue') },
      { path: 'PrivacyPolicy', component: () => import('../pages/internal-pages-casino/PrivacyPolicy.vue') },
      { path: 'TermsAndConditions', component: () => import('../pages/internal-pages-casino/TermsAndConditions.vue') },
    ],
  },

  { path: '/Racebook', component: () => import('../pages/Racebook.vue') },
  { path: '/SportsRules', component: () => import('../pages/SportsRules.vue') },
  { path: '/HouseRules', component: () => import('../pages/HouseRules.vue') },
  { path: '/SameGameParlayRules', component: () => import('../pages/SameGameParlayRules.vue') },
  { path: '/HorseTracks', component: () => import('../pages/HorseTracks.vue') },
  { path: '/BettingGuide', component: () => import('../pages/BettingGuide.vue') },
  {
    path: '/BettingTypes',
    redirect: '/BettingTypes/ParlayBets',
    component: () => import('../pages/BettingTypes.vue'),
    children: [
      { path: 'ParlayBets', component: () => import('../pages/internal-pages-betting-types/ParlayBets.vue') },
      { path: 'BuyingPoints', component: () => import('../pages/internal-pages-betting-types/BuyingPoints.vue') },
      { path: 'TeaserBets', component: () => import('../pages/internal-pages-betting-types/TeaserBets.vue') },
      { path: 'IfBets', component: () => import('../pages/internal-pages-betting-types/IfBets.vue') },
      { path: 'StraightWagers', component: () => import('../pages/internal-pages-betting-types/StraightWagers.vue') },
      { path: 'ReverseBets', component: () => import('../pages/internal-pages-betting-types/ReverseBets.vue') },
      { path: 'RoundRobinBets', component: () => import('../pages/internal-pages-betting-types/RoundRobinBets.vue') },
      { path: 'OtherBets', component: () => import('../pages/internal-pages-betting-types/OtherBets.vue') },
    ],
  },
  { path: '/HorseRacingGuide', component: () => import('../pages/HorseRacingGuide.vue') },
  { path: '/HorseRacingGlossary', component: () => import('../pages/HorseRacingGlossary.vue') },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    return { top: 0 }; // Siempre desplazar hacia arriba al cambiar de ruta
  }
});

export default router;
