import { createApp } from 'vue'
import App from './App.vue'
import "./assets/scss/main.scss"
import 'bootstrap/dist/css/bootstrap.min.css'; // Importar Bootstrap CSS
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

import router from './router'

const app = createApp(App);

app.component('font-awesome-icon', FontAwesomeIcon)
app.use(router);
app.mount('#app');