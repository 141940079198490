<template>
  <header class="header">
    <div class="header__preheader container">
      <a href="/" class="header__preheader--logo">
        <img class="header-content__img" src="../assets/images/logo.png" alt="WagerWire" />
      </a>

      <div class="login">
        <button class="login__form--button" @click="openModal('desktop')">LOGIN</button>
        <button class="btnmobile desktop" @click="openModal('desktop')">LOGIN DESKTOP</button>
        <button class="btnmobile mobile" @click="openModal('mobile')">LOGIN MOBILE</button>
      </div>
    </div>
    <!-- Aquí está el componente Modal -->
    <ModalComponent ref="modal" :formType="formType" />
  </header>
</template>

<script>
import ModalComponent from "@/components/modal.vue";

export default {
  name: "HeaderComponent",
  components: {
    ModalComponent,
  },
  data() {
    return {
      formType: 'desktop', // Define un tipo de formulario por defecto
    };
  },
  methods: {
    // Método para abrir el modal con el tipo de formulario especificado
    openModal(type) {
      this.formType = type; // Cambia el tipo de formulario
      this.$refs.modal.openModal(); // Se refiere al componente modal
    },
    moveLoginForm() {
      const form = document.querySelector(".login");
      const preheader = document.querySelector(".header__preheader");
      const nav = document.querySelector(".header-content");

      if (window.innerWidth <= 1024) {
        if (nav && !nav.contains(form)) {
          nav.appendChild(form);
        }
      } else {
        if (preheader && !preheader.contains(form)) {
          preheader.appendChild(form);
        }
      }
    },
  },
  mounted() {
    this.moveLoginForm();
    window.addEventListener("resize", this.moveLoginForm);
  },
  beforeUnmount() {
    window.removeEventListener("resize", this.moveLoginForm);
  },
};
</script>