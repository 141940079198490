<template>
  <div class="addFavoriteContainer">
    <button class="addFavorite" @click="openModal">Welcome! Bookmark This Page</button>

    <div class="modal-backdrop" v-if="showModal">
      <div class="modal-content__heading"></div>
      <div class="modal-content">
        <div>
          <!-- Aquí está el mensaje estático en puro HTML -->
          <p>
            To add this web app to the home screen open the browser option menu
            and tap on <strong>Add to homescreen</strong>.
            <small
              >The menu can be accessed by pressing the menu hardware button if
              your device has one, or by tapping the top right menu icon
              <span class="material-symbols-rounded">more_vert</span></small
            >
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      showModal: false, // Controla la visibilidad del modal
    };
  },
  methods: {
    openModal() {
      this.showModal = true; // Abre el modal
      // Cierra el modal después de 5 segundos
      setTimeout(() => {
        this.closeModal();
      }, 6000); // 5000ms = 5 segundos
    },
    closeModal() {
      this.showModal = false; // Cierra el modal
    },
  },
};
</script>

<style lang="scss" scoped>
.addFavoriteContainer {
  display: flex;
  justify-content: end;
  padding: 5px 20px;

  @media (min-width: 992px) {
       display: none;
      }

  p {
    margin: 0px;
  }

  .addFavorite {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

    &:hover{
      background-color: #bb2f3d;
    }
  }

  small > .material-symbols-rounded {
    position: relative;
    top: 5px;
  }
}
</style>>